body {
  background-color: #000;
  align-items: center;
  /* background-image: url("./background-fill.webp");  */
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.8;
  font-family: "new-order", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 15pt;
  line-height: 1.4;
  justify-content: space-evenly;
}

.header-1 {
  background-image: url("./ac-header.webp");
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 60%;
  min-width: 420px;
  min-height: 410px;
  @media only screen and (max-width: 900px) {
      width: 100%;
      min-width: 90%;
      min-height: none;
      align-self: center;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    min-height: 28vh;
    align-self: center;
}
  max-width: 100%;
  -webkit-background-size: 100%;
          -moz-background-size: 100%;
              -o-background-size: 100%;
                  background-size: 100%;
                      -webkit-background-size: cover;
                          -moz-background-size: cover;
                              -o-background-size: cover;
                                  background-size: cover;
}

.header-2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: auto;
  background-image: url("./150.webp");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: 999;
  overflow: visible;
  width:40%;
  min-height: 400px;
  max-width: 100%;
  @media only screen and (max-width: 900px) {
    display: none;
      width: 0%;
      min-height: 0;
      min-width: none;
  }
  @media only screen and (max-width: 600px) {
    display: none;
    width: 0%;
    min-height: 0;
    min-width: none;
  }
  -webkit-background-size: 100%;
          -moz-background-size: 100%;
              -o-background-size: 100%;
                  background-size: 100%;
                      -webkit-background-size: cover;
                          -moz-background-size: cover;
                              -o-background-size: cover;
                                  background-size: cover;
}

.full-height {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-image: url("./clubhouse-stencil.webp");
  background-size: contain;
  background-position: bottom 15vh left;
  background-repeat: no-repeat;
  background-color: transparent;
}

.flex-row {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: space-around;
  width: 100%;
  padding: 0px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
}

.socials {
  padding: 10px 10px 0px 0px;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
}

.socials a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 0px 10px 0px 10px;
}

.body a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.body a:hover {
  color: #7a6423
}

.button {
  color: #fff !important;
  font-weight: bold;
  text-decoration: none;
}

.primary-button {
  vertical-align: middle !important;
  text-decoration: none !important;
  align-content: center !important;
  background-color: #796423 !important;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center !important;
}

.flex-column {
  align-self: center;
  align-items: center;
  padding-top: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0px;
  justify-content: space-around;
  padding: 0px;
  width: 100%;
}

.padded {
  background-color: #7a6423;
  opacity: 0.9;
  height: 20vh;
}

.button {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 5px;
  width: 200px;
  text-align: center;
  font-family: "new-order", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.2rem;
  vertical-align: middle;
}

input {
  font-family: "new order", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  text-transform: lowercase;
}

.bold {
  font-weight: bold;
}

.body {
  display: block;
  opacity: 1;
  align-items: center;
  background-color: transparent;
  width: 94vw;
  max-width: 94vw;
  min-width: 300px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top:20px;
  padding-bottom:40px;
  @media only screen and (max-width: 1024px) {
    min-height: none;
    width: 80%;
    display: inline;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding-left:0px;
    padding-top: 0px;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  @media only screen and (max-width: 900px) {
    width: 95%;
    min-width: none;
    padding-left:0px;
    padding-top: 0px;
    margin-left: 0vw;
    margin-right: 0vw;
    justify-items: center;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    min-width: none;
    align-content: center;
    align-self: center;
    padding-left:0px;
    padding-top: 0px;
    margin-left: 3vw;
    margin-right: 0vw;
  }
  min-height: 90vh;
  color: white;
  border: 0px solid white;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: transparent;
  width: 90%;
  min-width: 80%;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top:10px;
  margin: 10px;
  margin-top:50px;
  min-height: 5px;
  color: white;
  font-size: 1.2rem;
  text-shadow: #7a6423;
  text-transform: lowercase;
  opacity: 0.6;
}

.footer a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 0px 10px 0px 10px;
}

.social-icon {
  width: 64px;
  height: 64px;
  @media only screen and (max-width: 900px) {
    width: 72px;
    height: 72px;
    padding: 10px;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 0%;
  width: 0%;
  position: absolute;
}

#sib-container {
  background-color: #000000 !important;
  text-align: center;
  padding: 0 0 0 0 !important;
}

#sib-form-block {
  padding: 0 0 0 0 !important
}

#sib-container input:-ms-input-placeholder {
  text-align: left;
  font-style: italic;
  color: #97977f;
}

#sib-container input::placeholder {
  text-align: left;
  font-style: italic;
  text-transform: lowercase;
  color: #c3c3c3;
}

#sib-container textarea::placeholder {
  text-align: left;
  font-style: italic;
  color: #c3c3c3;
}

#sib-container a {
  text-decoration: underline;
  color: #796423;
}

h1{
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 0px 0 0px 0;
}

h2 {
  font-weight: bold;
  color: #796423;
  text-align: center;
  padding: 0px 0 0px 0;
}

h3 {
  font-weight: 500;
  color: #796423;
  text-align: left;
  padding: 0px 0 0px 0;
}

h4 {
  font-weight: bold;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  padding: 0px 0 0px 0;
}

h6 {
  font-weight: 200;
  color: #796423;
  text-align: center;
  padding: 0px;
}
